export default function useTagManager() {
  function appendMetric(event, payload = {}) {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event,
        ...payload,
      });
    }
  }

  return { appendMetric };
}
