"use client";

import { useState, useEffect } from "react";
import Script from "next/script";
import { Box, Stack, Typography, Alert, AlertTitle } from "@mui/material";
import get from "lodash/get";

import { Button as BupButton, Link, Loading } from "components";
import ResponsiveStepper from "components/_MRui/wrappers/ResponsiveStepper";
import FormBuilder from "components/_MRui/wrappers/Form/FormBuilder";
import Snackbar from "components/_MRui/feedback/SnackBar";
import Heading from "components/_MRui/display/Heading";
import GenericMap from "components/_MRui/display/GenericMap";
import NavigationButtons from "components/_MRui/display/NavigationButtons";
import AppBackdrop from "components/_MRui/display/AppBackdrop";

import { useConfig, useAuth } from "contexts";

import useSecureSignup from "hooks/session/secureSignup/useSecureSignup";
import useSignupForm from "hooks/session/secureSignup/useSignupForm";
import useRecaptcha from "hooks/session/secureSignup/useRecaptcha";
import useTimer from "hooks/session/secureSignup/useTimer";

import useTagManager from "hooks/useTagManager";

import EmailValidationAlert from "./EmailValidationAlert";

const FormBuilderWrapper = (props) => (
  <FormBuilder
    {...props}
    containerSx={{ display: "flex", flexDirection: "column" }}
  />
);

const TimerAlertComponent = ({ showWarning, showExpiration }) => {
  if (!showWarning && !showExpiration) return null;
  return (
    <>
      {showWarning && !showExpiration && (
        <Alert severity="warning">
          <AlertTitle>¡Atención!</AlertTitle>
          <Typography>
            Tu tiempo para el registro se agotará en 2 minutos. Si necesitas mas
            tiempo refresca la página y vuelve a comenzar.
          </Typography>
        </Alert>
      )}
      {showExpiration && (
        <Alert severity="error">
          <AlertTitle>¡Tiempo de registro se ha agotado!</AlertTitle>
          <Typography>
            Por favor, recarga la página e intenta nuevamente.
          </Typography>
        </Alert>
      )}
    </>
  );
};

export default function SecureSignup({ partner = null, signUpCode = null }) {
  const { documents, company } = useConfig();

  const { recaptchaToken, getFreshToken, captchaScriptProps } = useRecaptcha();
  const { showWarning, showExpiration } = useTimer();
  const { appendMetric } = useTagManager();

  const [emailValidationRetryCount, setEmailValidationRetryCount] = useState(0);

  const {
    loading: isTokenLoading,
    validateEmail,
    validateRut,
    validationLoading,
    signupLoading,
    signinLoading,
    submitSignup,
    submitSignin,
  } = useSecureSignup({
    onTokenExpiration: handleTokenExpiration,
  });
  const [errorAlert, setErrorAlert] = useState(null);
  const [snackbarText, setSnackbarText] = useState(null);

  const [validationStep, setValidationStep] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const { fingerprint } = useAuth();

  const { FIELD_CONFIGS, signupFormik } = useSignupForm(signUpCode);

  useEffect(() => {
    switch (activeStep) {
      case 1:
        getFreshToken("validateRut");
        return;
      case 3:
        getFreshToken("signup");
        return;
      default:
        return;
    }
  }, [activeStep]);

  useEffect(() => {
    if (showExpiration) {
      appendMetric("registro-normal-expirado", {
        signUpCode: signupFormik.values.signUpCode,
        fingerprint: fingerprint,
        step: activeStep + 1,
      });
    }
  }, [showExpiration]);

  const STEPS = [
    {
      label: "¡Bienvenid@!",
      Component: ({ formik }) => {
        return (
          <>
            <Heading
              title="✨ ¡Bienvenid@ a BondUP! Completemos tu registro ✨"
              text="Para comenzar, ingresa tus datos de contacto."
            />
            <FormBuilderWrapper
              fieldConfigs={FIELD_CONFIGS[0]}
              formik={formik}
            />
            {partner?.Landing ? partner.Landing() : null}
          </>
        );
      },
      customValidation: validateContactInfo,
    },
    {
      label: "Identificación",
      Component: ({ formik }) => (
        <>
          <Heading
            title="🆔 Tu identificación 🆔"
            text="Ingresa tu número de RUT para verificar tu identidad."
          />
          <FormBuilderWrapper fieldConfigs={FIELD_CONFIGS[1]} formik={formik} />
        </>
      ),
      customValidation: validateIdentity,
    },
    {
      label: "Validación",
      hideNext: !validationStep || validationStep.type === "error",
      Component: ({ formik }) => {
        const isError = validationStep.type === "error";
        const isCaptchaError = validationStep?.error?.type === "captcha";

        const getPrompts = () => {
          if (isCaptchaError) {
            return ["🔒 Verificación de seguridad 🔒", ""];
          } else if (isError) {
            return ["🚫 No podemos continuar 🚫", "Lo sentimos, pero no puedes registrarte😢."];
          }
          return ["📄 Confirma tus datos 📄", "Por favor, confirma que sea correcta:"];
        }
        const [title, text] = getPrompts();

        return (
          <>
            <Heading title={title} text={text} />
            {isError && (
              <Alert severity={validationStep?.error?.severity || "error"}>
                <Typography variant="h6">
                  {validationStep?.error?.message}
                </Typography>
                {isCaptchaError && (
                  <BupButton sx={{ mt: 2 }} onClick={() => location.reload()}>
                    Recargar la página y volver a intentar
                  </BupButton>
                )}
              </Alert>
            )}
            {!isError && (
              <>
                <FormBuilderWrapper
                  fieldConfigs={FIELD_CONFIGS[2]}
                  formik={formik}
                />
                <Stack direction="column">
                  <Typography variant="h6">
                    {'✅ Si todo está bien, presiona "Continuar".'}
                  </Typography>
                  <Typography variant="h6">
                    {"🔄 Si hay un error, vuelve atrás y revisa tu RUT."}
                  </Typography>
                </Stack>
              </>
            )}
          </>
        );
      },
    },
    {
      label: "Ubicación",
      Component: ({ formik }) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Heading
              title="🏡 Comuna donde vives 🏡"
              text="Escribe tu comuna de residencia en la barra de búsqueda."
            />
            <FormBuilderWrapper
              fieldConfigs={FIELD_CONFIGS[3]}
              formik={formik}
            />
            <GenericMap address={formik.values.location} disableLink />
          </Box>
        );
      },
    },
    {
      label: "Tu contraseña",
      hideNext: true,
      Component: ({
        loading,
        formik,
        handleSubmitSignup,
        documents,
        company,
      }) => {
        return (
          <>
            <Heading
              title="🔑 ¡Tus datos de ingreso están listos! 🔑"
              text="Guarda bien esta información, la necesitarás para entrar a la BondUP."
            />
            <Alert severity="info">
              Tu contraseña será los primeros 6 digitos de tu RUT.
            </Alert>
            <FormBuilderWrapper
              fieldConfigs={FIELD_CONFIGS[4]}
              formik={formik}
            />
            <small
              className="BupLogin-caption"
              style={{ marginBottom: "1rem" }}
            >
              Al hacer clic en «Aceptar y unirse», aceptas las{" "}
              <Link href={documents?.terms}>Condiciones de uso</Link>, la{" "}
              <Link href={documents?.privacy}>Política de privacidad</Link> y la{" "}
              <a>Política de cookies</a> de {company?.alias}.
            </small>
            <BupButton
              fullWidth
              onClick={() => handleSubmitSignup(formik)}
              loading={loading}
            >
              Aceptar y unirse
            </BupButton>
          </>
        );
      },
    },
  ];
  const isLastStep = activeStep === STEPS.length - 1;

  async function validateContactInfo() {
    const { error } = await validateEmail(signupFormik.values.email);
    if (error) {
      signupFormik.setFieldError("email", error);
      signupFormik.setTouched({ ...signupFormik.touched, email: true }, false);
      setEmailValidationRetryCount(emailValidationRetryCount + 1);
      return false;
    }

    return true;
  }

  async function validateIdentity() {
    const rutValue = signupFormik.values.info.rut;
    const { success, data, error } = await validateRut(
      rutValue,
      recaptchaToken
    );
    if (!success) {
      setValidationStep({ type: "error", error: error });
      return true;
    } else {
      setValidationStep({ type: "success" });
      const { other, info } = data;
      const { firstName, lastName, birth, gender } = info;

      const generatedPassword = rutValue.replace(/\./g, "").slice(0, 6);
      signupFormik.setValues({
        ...signupFormik.values,
        password: generatedPassword,
        info: {
          ...signupFormik.values.info,
          firstName,
          lastName,
          birth,
          gender,
        },
        other: {
          ...signupFormik.values.other,
          ...other,
        },
      });
      return true;
    }
  }

  function handleTokenExpiration() {
    setErrorAlert({
      severity: "error",
      title: "Tiempo de registro se ha agotado",
      message: "Por favor, recarga la página e intenta nuevamente.",
    });
  }

  const handleNext = async () => {
    const currentFields = FIELD_CONFIGS[activeStep] || [];
    const currentFormik = signupFormik;
    const errors = await currentFormik.validateForm();
    const hasErrors = currentFields.some((field) => get(errors, field.name));

    if (!hasErrors) {
      const { customValidation } = STEPS[activeStep];
      if (customValidation) {
        const isValid = await customValidation();
        if (!isValid) {
          return;
        }
      }
      setErrorAlert(null);
      appendMetric("registro-normal-paso", {
        step: activeStep + 1,
        signUpCode: signupFormik.values.signUpCode,
        fingerprint: fingerprint,
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      const currentFields = FIELD_CONFIGS[activeStep] || [];
      currentFormik.setTouched(
        currentFields.reduce((acc, field) => {
          const keys = field.name.split(".");
          let current = acc;

          keys.forEach((key, index) => {
            if (!current[key]) {
              current[key] = index === keys.length - 1 ? true : {};
            }
            current = current[key];
          });

          return acc;
        }, {})
      );
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      let newStep = prevActiveStep - 1;
      while (STEPS[newStep].optional && STEPS[newStep].hidden) {
        newStep -= 1;
      }
      return newStep;
    });
  };

  const handleSubmitSignup = async ({ values }) => {
    await getFreshToken();

    const formattedValues = { ...values };
    delete formattedValues.location;
    delete formattedValues.other;

    const response = await submitSignup(formattedValues, recaptchaToken);
    if (response.success) {
      appendMetric("registro-normal-completo", {
        signUpCode: signupFormik.values.signUpCode,
        fingerprint: fingerprint,
      });
      await submitSignin(
        {
          email: signupFormik.values.email,
          password: signupFormik.values.password,
        },
        signupFormik
      );
    }
    setSnackbarText({
      message: response.message,
      severity: response.severity,
    });
  };

  if (isTokenLoading) {
    return <Loading />;
  }

  const currentStep = STEPS[activeStep];
  const { hideNext = false, hideBack = false } = currentStep;
  return (
    <>
      <Script {...captchaScriptProps} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 10,
        }}
      >
        <ResponsiveStepper
          steps={STEPS}
          activeStep={activeStep}
          sx={{
            width: "100%",
            maxWidth: "650px",
          }}
        >
          <form onSubmit={signupFormik.handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {currentStep.Component({
                formik: signupFormik,
                handleSubmitSignup,
                loading:
                  signupLoading ||
                  signinLoading ||
                  validationLoading ||
                  isTokenLoading,
                documents,
                company,
              })}
            </Box>
            <NavigationButtons
              activeStep={activeStep}
              isLastStep={isLastStep}
              handleBack={handleBack}
              handleNext={handleNext}
              hideBack={hideBack}
              hideNext={hideNext}
              loading={
                signupLoading ||
                signinLoading ||
                validationLoading ||
                isTokenLoading
              }
            />
          </form>
          {errorAlert && (
            <Alert severity={errorAlert.severity || "info"}>
              <AlertTitle>
                {errorAlert.title || "¡Ups! Algo salió mal"}
              </AlertTitle>
              {errorAlert.message || "Por favor, intenta nuevamente."}
            </Alert>
          )}
          <TimerAlertComponent
            showWarning={showWarning}
            showExpiration={showExpiration}
          />
          <EmailValidationAlert
            emailValidationRetryCount={emailValidationRetryCount}
          />
        </ResponsiveStepper>
        <Snackbar onClose={() => setSnackbarText(null)} alert={snackbarText} />
        <AppBackdrop open={validationLoading} text="Validando..." />
        <AppBackdrop
          open={signupLoading || signinLoading}
          text="Un momento, estamos procesando tu registro"
        />
      </Box>
    </>
  );
}
