import { useState, useEffect } from "react";

export default function useTimer(expirationTimeMinutes = 10, warnMeBefore = 2) {
  const [showWarning, setShowWarning] = useState(false);
  const [showExpiration, setShowExpiration] = useState(false);

  useEffect(() => {
    if (expirationTimeMinutes <= 0 || warnMeBefore <= 0) return;

    const warningTime = (expirationTimeMinutes - warnMeBefore) * 60 * 1000;
    const timer = setTimeout(() => setShowWarning(true), warningTime);

    const expirationTime = expirationTimeMinutes * 60 * 1000;
    const expirationTimer = setTimeout(
      () => setShowExpiration(true),
      expirationTime
    );

    return () => {
      clearTimeout(timer);
      clearTimeout(expirationTimer);
    };
  }, [expirationTimeMinutes, warnMeBefore]);

  return { showWarning, showExpiration };
}
