import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { useState, useEffect } from "react";

export default function EmailValidationDialog({ emailValidationRetryCount }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (emailValidationRetryCount > 1) {
      setOpen(true);
    }
  }, [emailValidationRetryCount]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{ fontSize: "1.2rem", fontWeight: "bold", textAlign: "center" }}
      >
        ¡Ya estás registrado con este correo!
      </DialogTitle>

      <DialogContent>
        <Typography sx={{ fontSize: "1rem", mb: 2, textAlign: "center" }}>
          Para continuar, elige una opción:
        </Typography>

        <Stack spacing={2}>
          <Button
            variant="contained"
            color="primary"
            href="/"
            fullWidth
            sx={{
              fontSize: "1rem",
              padding: "12px",
            }}
          >
            Iniciar sesión
          </Button>

          <Button
            variant="outlined"
            color="primary"
            href="/?view=restore"
            fullWidth
            sx={{
              fontSize: "1rem",
              padding: "12px",
            }}
          >
            Restablecer contraseña
          </Button>

          <Button
            variant="text"
            color="primary"
            onClick={() => handleClose()}
            fullWidth
            sx={{
              fontSize: "1rem",
              padding: "12px",
            }}
          >
            Intentar con otro correo
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
