"use client";

import { Fab, Zoom, useMediaQuery, useTheme } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import helper from "utils/helper";
import { useConfig } from "contexts";

const HelpButton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { contact } = useConfig();

  function handleClick() {
    const supportUrl = helper.getWhatsappUrl(contact?.phone);
    window.open(supportUrl, "_blank");
  }

  return (
    <div
      style={{
        position: "fixed",
        bottom: "75px",
        left: isMobile ? "16px" : "24px",
        zIndex: 1000,
      }}
    >
      <Zoom
        in={true}
        timeout={500}
        style={{
          transitionDelay: "500ms",
        }}
      >
        <Fab
          color="primary"
          aria-label="¿Necesitas ayuda?"
          onClick={handleClick}
          variant="extended"
          size={isMobile ? "small" : "large"}
        >
          <HelpOutlineIcon sx={{ mr: 1 }} />
          ¿Necesitas ayuda?
        </Fab>
      </Zoom>
    </div>
  );
};

export default HelpButton;
